<template>
    <div class="callPolice" 
        :class="pageState==2?'between':''"
        v-if="is_callPolice">
        <!-- 一键报警 -->
        <div class="callPolice-content" v-if="pageState==1">
            <div class="page-title">
                一键报警
                <img @click="switchShow" src="../assets/img/nav_closepage.png" alt="" srcset="">
            </div>
            <div class="div-slide">
                <div class="mapLocation">
                    <baidu-map 
                    :double-click-zoom="true"
                    :pinch-to-zoom="true"
                    :center="center"
                    class="mapLocation"
                    :zoom="20"
                    @click="getMapLocation"
                    @ready="mapReady">
                        <bm-marker
                            :position="{ lng: center.lng, lat: center.lat }"
                            :dragging="true"
                            animation="BMAP_ANIMATION_BOUNCE">
                        </bm-marker>
                        <bm-geolocation anchor="BMAP_ANCHOR_TOP_LEFT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                    </baidu-map>
                </div>
                <div class="mapLocation-info">
                    <div>{{addr}}</div>
                    <div class="title-info">
                        请填写信息<span>(单车事故请填写相同手机)</span>
                    </div>
                    <div class="CallPoliceform">
                        <el-form :model="formInfo" :rules="policeForm">
                            <el-row :gutter="20">
                                <el-col :span="10" :offset="0">
                                    <el-form-item prop="parties">
                                        <el-input class="parties" 
                                                v-model="formInfo.parties1"
                                                placeholder="当事人1*姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="14" :offset="0">
                                    <el-form-item prop="phone">
                                        <el-input class="phone"
                                                v-model="formInfo.phone1"
                                                type="number" 
                                                maxlength="11"
                                                oninput="if(value.length>11)value=value.slice(0,11)"
                                                placeholder="11位手机号"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="10" :offset="0">
                                    <el-form-item prop="parties">
                                        <el-input class="parties" 
                                                v-model="formInfo.parties2"
                                                placeholder="当事人2*姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="14" :offset="0">
                                    <el-form-item prop="phone">
                                        <el-input class="phone"
                                                v-model="formInfo.phone2"
                                                type="number" 
                                                maxlength="11"
                                                oninput="if(value.length>11)value=value.slice(0,11)"
                                                placeholder="11位手机号"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <el-button :disabled="is_callPoliceButton" 
                        type="danger" 
                        class="callPoliceButton"
                        @click="callpolice()"
                    >确认报警</el-button>
                </div>
            </div>
        </div>
        <!-- 报警成功提示 -->
        <div class="succeedPop" v-if="pageState==2">
            <img src="../assets/img/alarmsuccessful.png" alt="">
            <div>报警成功</div>
            <div>报案编号:XXXXXXXXXXXX</div>
            <el-button class="succeedPopButton"
                       type="primary"
                       @click="switchoverPage(3)"
                       :disabled="is_succeedPopButton"
                       >补充信息{{count}}</el-button>
        </div>
        <!-- 补充信息 -->
        <div class="supplementary" v-if="pageState==3">
            <div class="page-title border-bottom">
                补充信息
                <img @click="switchShow" src="../assets/img/nav_closepage.png" alt="" srcset="">
            </div>
            <div class="div-slide">
                <div class="p30">
                    <div class="title-info patch-info">
                        照片<span>请拍摄双方行驶证、驾驶证、现场前后远景；</span>
                    </div>
                    <div class="phoneList">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            v-for="item,index in phonetype"
                            :key="index"
                            :on-success="function (res,file){return handleAvatarSuccess(res,file,index)}"
                            :before-upload="beforeAvatarUpload">
                            <!-- 背景图片 -->
                            <img class="backgroundImg" 
                                 :src="item.background"
                                 v-if="item.background && !item.imageUrl">
                            <!-- 上传主图 -->
                            <img v-if="item.imageUrl" 
                                 :src="item.imageUrl" 
                                 class="avatar">
                            <!-- 删除按钮 -->
                            <img v-if="item.imageUrl" 
                                 @click.stop="closeAvatar(index)"
                                 src="../assets/img/img_delete.png" 
                                 class="closeAvatar">
                            <!-- 添加图片 -->
                            <div class="avatar-type" v-else>
                                <img src="../assets/img/addimg.png" alt="" srcset="">
                                <span>{{item.type}}</span>
                            </div>
                        </el-upload>
                    </div>
                    <el-form :model="patchInfo" ref="patchForm" :rules="patchForm">
                        <div class="title-info patch-info">当事人1</div>
                        <el-row :gutter="20">
                            <el-col :span="15" :offset="0">
                                <el-form-item prop="identity">
                                    <el-input v-model="patchInfo.identity1"
                                            placeholder="请输入身份证号码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="9" :offset="0">
                                <el-form-item prop="license">
                                    <el-input v-model="patchInfo.license1"
                                            placeholder="请输入车牌号"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="title-info patch-info">当事人2</div>
                        <el-row :gutter="20">
                            <el-col :span="15" :offset="0">
                                <el-form-item prop="identity">
                                    <el-input v-model="patchInfo.identity2"
                                            placeholder="请输入身份证号码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="9" :offset="0">
                                <el-form-item prop="license">
                                    <el-input v-model="patchInfo.license2"
                                            placeholder="请输入车牌号"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <el-button 
                    type="primary" 
                    class="callPoliceButton"
                >确定</el-button>

            </div>
        </div>

    </div>
</template>
<script>

export default {
    name:'callPolice',
    data() {
        return {
            phonetype:[
                {
                    type:'碰撞细节',
                    imageUrl:'',
                    background:require('../assets/img/upBgImg/1.jpg'),
                },
                {
                    type:'前全景',
                    imageUrl:'',
                    background:require('../assets/img/upBgImg/2.jpg'),
                },
                {
                    type:'后全景',
                    imageUrl:'',
                    background:require('../assets/img/upBgImg/3.jpg'),
                },
                {
                    type:'行驶证',
                    imageUrl:'',
                    background:require('../assets/img/upBgImg/4.png'),
                },
                {
                    type:'驾驶证',
                    imageUrl:'',
                    background:require('../assets/img/upBgImg/5.png'),
                },
                {
                    imageUrl:''
                },
            ],
            formInfo:{
                parties1:'',
                phone1:'',
                parties2:'',
                phone2:'',
            },
            patchInfo:{
                identity1:'',
                license1:'',
                identity2:'',
                license2:'',
            },
            pageState:1,    //1：填表 2：成功 3:补充信息
            count:'3s',
            is_succeedPopButton:false,   
            is_callPolice:false,     //控制显示组件
            patchForm:{
                identity:[
                    {
                        required:true,
                        message:'请输入身份证号码',
                        trigger:'blur'
                    },
                ],
                license:[
                    {
                        required:true,
                        message:'请输入车牌号',
                        trigger:'blur'
                    }
                ]
            },
            policeForm:{
                phone:[
                    {
                        required:true,
                        message:'请输入手机号',
                        trigger:'blur'
                    },
                    {   min: 11, 
                        message: '请输入正确的手机号码', 
                        trigger: 'blur'
                    }
                ],
                parties:[
                    {
                        required:true,
                        message:'请输入姓名',
                        trigger:'blur'
                    }
                ]
            },
            CodeTimer:null,   //定时器

            dialogImageUrl: '',
            dialogVisible: false,
            point:20,
            center: {
                lng: "",
                lat: ""
            },
            addr:"",        //地址信息
        }
    },
    computed:{
        is_callPoliceButton(){
            let f = this.formInfo;
            return (f.parties1 & f.parties2 & f.phone1 & f.phone2)?false:true
        }
    },
    mounted(){
    },
    methods: {
        //获取点击下标
        getMapLocation(e){
            this.center.lng = e.point.lng;
            this.center.lat = e.point.lat;
            const _this = this
            const gc = new this.BMap.Geocoder()
            gc.getLocation(e.point, function (rs) {
                _this.addr = rs.address;
            })
        },
        mapReady({ BMap, map }) {
            const that = this;
            // 选择一个经纬度作为中心点
            let geolocation = new BMap.Geolocation();
            let geocoder = new BMap.Geocoder()
            geolocation.getCurrentPosition(function(r) {
                that.center.lng = r.longitude;
                that.center.lat = r.latitude;
                geocoder.getLocation(r.point, function (rs) {
                    that.addr = rs.address;
                })
            });
            this.BMap = BMap;
            this.map = map;
        },



        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        }, 
        handleAvatarSuccess(res, file, index) {
            console.log(res, file,index)
            this.phonetype[index].imageUrl = URL.createObjectURL(file.raw);
            console.log(index,this.phonetype.length)
            if(index==this.phonetype.length-1){
                this.phonetype.push({
                    imageUrl:''
                })
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        //清除图片
        closeAvatar(index){
            if(this.phonetype[index].type){
                this.phonetype[index].imageUrl = null;
            }else{
                this.phonetype.splice(index, 1)　
            }
        },



        callpolice(){
            if(this.btnBool)return;
                this.pageState = 2;
                this.btnBool = true;
                let i = 2;
                this.CodeTimer = setInterval(() => {
                    this.count =  i + 's';
                    i--;
                    if (i < 0) {
                        this.btnBool = false;
                        this.count = '';
                        this.is_succeedPopButton = false;
                        clearInterval(this.CodeTimer);
                    }
            }, 1000);
        },
        switchShow(index){
            this.pageState = index || 1;
            this.is_callPolice = !this.is_callPolice;
        },
        //切换显示区块
        switchoverPage(index){
            this.pageState = index;
        }
    }
}
</script>
<style scoped lang="less">
    .phoneList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .phoneList::after{
        content: "";
        width: 212px;
    }
    .avatar-uploader{
        position: relative;
        margin-bottom: 20px;
        width: 212px;
        height: 146px;
        border: 2px dotted #D9D9D9;
        border-radius: 16px;
        /deep/ .el-upload {
            width: 212px;
            height: 146px;
        }
    }
    .backgroundImg{
        position: absolute;
        top: 0;
        left: 0;
        width: 212px;
        height: 146px;       
        opacity: 0.3;
    }
    .avatar{
        width: 212px;
        height: 146px;
    }
    .closeAvatar{
        position: absolute;
        height: 48px;
        width: 48px;
        top: -24px;
        right: -24px;
    }
    .avatar-type{
        text-align: center;
        line-height: 146px;
        width: 212px;
        font-size: 32px;
        color: #666666;
        letter-spacing: 0;
        font-weight: 400;
        >img{
          width: 32px;
          height: 32px;
          margin-right: 10px;
          vertical-align: middle;
        }
        >span{
            vertical-align: middle;
        }
    }
    //成功弹窗
    .between{
        align-items: center;
        justify-content: center;
    }
    .succeedPop{
        background: #FFFFFF;
        border-radius: 16px;
        width: 622px;
        height: 646px;
        text-align: center;
        >img{
            height: 180px;
            width: 180px;
            margin: 96px auto 36px auto;
        }
        >div:nth-child(2){
            font-size: 46px;
            color: #333333;
            letter-spacing: 0;
            line-height: 46px;
            font-weight: 600;
        }
        >div:nth-child(3){
            font-size: 28px;
            color: #999999;
            letter-spacing: 0;
            line-height: 28px;
            font-weight: 400;
            margin: 32px auto 96px auto;
        }
        .succeedPopButton{
            width: 526px;
            height: 88px;
            font-size: 32px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 32px;
            font-weight: 600;
        }
    }
    .CallPoliceform{
        margin: 30px 30px 0px 30px;
    }
    //修改按钮样式
    /deep/ .el-input__inner{
        height: 88px;
        border: none;
        background-color: #f3f3f3;
        font-size: 32px;
        color: #333333;
        letter-spacing: 0;
        line-height: 32px;
        font-weight: 400;
    }

    // 标题
    .page-title{
        position: relative;
        height: 104px;
        font-size: 36px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 104px;
        font-weight: 600;
        >img{
            height: 44px;
            width: 44px;
            top: 30px;
            right: 30px;
            position: absolute;
        }
    }
    //介绍
    .title-info{
        font-size: 32px;
        color: #333333;
        letter-spacing: 0;
        line-height: 32px;
        font-weight: 600;
        margin: 0 30px;
        margin-top: 64px;
        >span{
            margin-left: 10px;
            font-size: 24px;
            color: #B6B6B6;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            font-weight: 400;
        }
    }
    .patch-info{
        margin: 0px 0px 24px 0px;
    }
    .supplementary{
        background-color: #FFF;
        border-radius: 32px 32px 0 0;
        width: 750px;
        margin-top: auto;
    }
    .callPolice{
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        z-index: 2000;
        .callPolice-content{
            background-color: #FFF;
            border-radius: 32px 32px 0 0;
            width: 750px;
            margin-top: auto;
            .mapLocation{
                height: 600px;
                width: 750px;
            }
            .mapLocation-info{
                
                >div:nth-child(1){
                    background: #F3F3F3;
                    border-radius: 8px;
                    width: 622px;
                    height: 32px;
                    font-size: 32px;
                    color: #333333;
                    letter-spacing: 0;
                    line-height: 32px;
                    font-weight: 400;
                    padding: 28px 32px;
                    margin: 30px;
                }
            }
        }
    }
    //按钮样式
    .callPoliceButton{
        width: 750px;
        height: 104px;
        // background: #ED4D58;
        font-size: 36px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
        border-radius: 0;
    }
    .border-bottom{
        border-bottom: 0.5px solid #E4E4E4;
    }
    .mapLocation{

    }
</style>