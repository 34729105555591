<template>
    <div class="home">
        <div class="head-title">
            <img @click="pageBack()" src="../assets/img/nav_leftreturn.png" alt="">
            <img src="../assets/img/nav_closepage.png" alt="">
            <div>嘉兴事故云处</div>
        </div>
        <div class="head-title-placeholder"></div>
        <div class="home-title-background"></div>
        <div class="switchType" @click="bindswitchType">
            <img src="../assets/img/switchType.png">
            <span>开启{{is_switchType?'长辈版':'青春版'}}</span>
        </div>
        <img class="customer" @click="OpenDialog()" src="../assets/img/customer_service.png">
        <div class="home-call">
            <el-button @click="callButton" class="callButton">一键报警</el-button>
            <div class="callHint">
                <img src="../assets/img/ic_notice.png" alt="" srcset="">
                <div>
                    <MarqueeLeftVue :sendVal="newItems"/>
                </div>
            </div>
        </div>
        <div class="tabs">
            <div class="tabs-act" :class="`tabs-act`+ tabsIndex"></div>
            <div v-for="tab,index in tabs" 
                :key="index"
                :class="tabsIndex == tab.tabId?'tabs-div-act':''"
                @click="selectTab(tab.tabId)" >
                {{tab.tabName}}
                {{tab.tabId==1?'('+reported_item.length+')':''}}
                {{tab.tabId==2?'('+trailers_service.length+')':''}}
                {{tab.tabId==3?'('+videoList.length+')':''}}
            </div>
        </div>
        <div class="tabs-content">
            <!-- 我的报案 -->
            <div v-show="tabsIndex==1">
                <ReportedItemVue v-for="item,index in reported_item"
                                :key="index"
                                :item="item"
                                :v-if="reported_item.length"
                                :homeOperation="homeOperation"
                                 />
                <el-empty v-if="!reported_item.length" description="暂无报警记录"></el-empty>
            </div>
            <!-- 拖车服务 -->
            <div v-show="tabsIndex==2">
                <el-select class="insureType" v-model="insureType" placeholder="请选择">
                    <el-option
                    v-for="item in insureItmeType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <TrailerItemVue ref="trailerItemVue"
                                v-for="item,index in trailers_service"
                                :key="index"
                                :item="item"
                                :v-if="trailers_service.length"
                                :dial_Phone="dial_Phone"
                                />
                <el-empty v-if="!trailers_service.length" description="暂无服务记录"></el-empty>
            </div>
            <!-- 安全教育 -->
            <div v-show="tabsIndex==3">
                <div class="education">
                    <div class="flex-List">
                        <div class="safetyType" 
                            :class=" item.id == safetyTypeIndex ?'safetyType-act':''" 
                            @click="bindSafetyTypeIndex(item.id)"
                            v-for="item,index in safetyType" 
                            :key="index">
                            {{item.TypeName}}{{item.id}}
                        </div>
                    </div>
                    <div class="flex-List" :v-if="videoList.length">
                        <div class="videoList" v-for="(item,i) in videoList" :key="i">
                            <div class="videoTime">{{item.time}}</div>
                            <div class="video-js" @click="palyVideo(item.src)">

                            </div>
                        </div>
                    </div>
                </div>
                <el-empty v-if="!videoList.length" description="暂无数据"></el-empty>
            </div>
        </div>
        <!-- 提示 -->
        <div class="manage-interests">
            本服务由浙江政务服务网，嘉兴交警支队提供<br/>
            服务热线：0573-82085279
        </div>
        <!-- 播放视频 -->
        <div class="video_div" v-show="is_playVideo"
            @click="closeVideo"
        >
            <video id="myVideo"
                    playsinline="true"
                    class="video-js">
                <source :src="videoSrc" type="video/mp4">
            </video>
        </div>
        <!-- 服务窗口 -->
        <ChatWindowVue ref="chatWindowVue"/>
        <!-- 一键报警 -->
        <CallPoliceVue ref="callPoliceVue"/>
        <!-- 签字模块 -->
        <SignatureVue ref="signatureVue"/>
        <!-- 详情模块 -->
        <ViewDetailsVue ref="viewDetailsVue"/>
        <!-- 评论模块 -->
        <EvaluationVue ref="evaluationVue"/>
        <!-- 拖车弹窗 -->
        <TrailerPopVue :phone_number="phone_number" ref="trailerPopVue"/>
    </div>
</template>
<script>
import ViewDetailsVue from '../components/ViewDetails.vue';
import CallPoliceVue from '../components/CallPolice.vue';
import ChatWindowVue from '../components/ChatWindow.vue';
import ReportedItemVue from '../components/ReportedItem.vue';
import SignatureVue from '../components/Signature.vue';
import TrailerItemVue from '../components/TrailerItem.vue';
import EvaluationVue from '../components/Evaluation';
import TrailerPopVue from '../components/common/TrailerPop.vue';
import MarqueeLeftVue from '../components/common/MarqueeLeft.vue';

export default {
    name:'home',
    components:{
        ReportedItemVue,
        TrailerItemVue,
        ChatWindowVue,
        CallPoliceVue,
        SignatureVue,
        ViewDetailsVue,
        EvaluationVue,
        TrailerPopVue,
        MarqueeLeftVue
    },
    data() {
        return {
            is_switchType:true,
            tabs:[
                {
                    tabName:'我的报案',
                    tabId:1
                },
                {
                    tabName:'拖车服务',
                    tabId:2
                },
                {
                    tabName:'安全教育',
                    tabId:3
                }
            ],
            insureItmeType: [{
                value: '1',
                label: '人保财险'
            }, {
                value: '2',
                label: '太平洋保险'
            }],
            insureType:null,
            tabsIndex:1,
            safetyTypeIndex:1,
            is_playVideo:false,     //播放视频
            videoSrc:null,
            newItems:[
                {
                    detail:'事故照片、驾驶证、行驶证上传成功后，您可以查看审核进度.'
                }
            ],
            safetyType:[
                {
                    TypeName:'闯红灯',
                    id:1
                },
                {
                    TypeName:'追尾',
                    id:2
                },
                {
                    TypeName:'未按规定让行',
                    id:3
                },
                {
                    TypeName:'其他',
                    id:4
                },
            ],
            reported_item:[
                {
                    report_Number:'2021061412345678',                     //报案编号
                    accident_Type:'倒车',                                 //事故类型
                    police_Time:'2021-06-14 18:07:45',                    //报警时间
                    accident_Site:'嘉兴市蓝湖区越秀路132号越秀路132号',    //事故地点
                    report_State:'1',                                     //报案状态
                },
                {
                    report_Number:'2021061412345678',
                    accident_Type:'倒车',
                    police_Time:'2021-06-14 18:07:45',
                    accident_Site:'嘉兴市蓝湖区越秀路132号越秀路132号越秀…',
                    report_State:'2',
                },
                {
                    report_Number:'2021061412345678',
                    accident_Type:'追尾',
                    police_Time:'2021-06-14 18:07:45',
                    accident_Site:'嘉兴市蓝湖区越秀路13号',
                    report_State:'3',
                },
                {
                    report_Number:'2021061412345678',
                    accident_Type:'追尾',
                    police_Time:'2021-06-14 18:07:45',
                    accident_Site:'嘉兴市蓝湖区越秀路12号',
                    report_State:'4',
                },
            ],
            phone_number:'',    
            //拖车服务
            trailers_service:[
                {
                    companyName:'嘉兴金通汽车有限公司',         //公司名称
                    companyIhpone:'有购买商业险中车损险的车辆可以免费拖车、只够买交强险或未购买商业险车损险无提供免费施救。',       //简介
                    insuranceType:'人保财险',                   //保险类型
                    companyTel:'95500',                         //公司联系号码
                },
                {
                    companyName:'杭州翼动汽车服务有限公司',
                    companyIhpone:'有购买商业险中车损险的车辆可以免费拖车、只够买交强险或未购买商业险车损险无提供免费施救。',
                    insuranceType:'太平洋保险',
                    companyTel:'13375811635',
                },
                {
                    companyName:'江苏亿科达科技发展有限公司',
                    companyIhpone:'线下支付',
                    insuranceType:'人保财险',
                    companyTel:'95518',
                },
                {
                    companyName:'嘉兴金通汽车有限公司',
                    companyIhpone:'有购买商业险中车损险的车辆可以免费拖车、只够买交强险或未购买商业险车损险无提供免费施救。',
                    insuranceType:'人保财险',
                    companyTel:'13656324512',
                },
            ],
            videoList:[
              {
                  image:'',
                  src:'https://www.w3school.com.cn/example/html5/mov_bbb.mp4',
                  time:"00:10",
                  id:0,
                  pic:"",
              },
              {
                  image:'',
                  src:'https://www.w3school.com.cn/example/html5/mov_bbb.mp4',
                  time:"00:10",
                  id:2,
                  pic:"",
              },
              {
                  image:'',
                  src:'https://www.w3school.com.cn/example/html5/mov_bbb.mp4',
                  time:"00:10",
                  id:2,
                  pic:"",
              },
              {
                  image:'',
                  src:'https://www.w3school.com.cn/example/html5/mov_bbb.mp4',
                  time:"00:10",
                  id:2,
                  pic:"",
              },
            ],
            myPlayer:null,
        }
    },
    mounted(){
    },
    methods: {
        //返回上一页
        pageBack(){
            
            this.$router.back();
        },
        //切换版本
        bindswitchType(){
            this.is_switchType = !this.is_switchType;
        },
        //拨打电话
        dial_Phone(phone_number){
            this.phone_number = phone_number;
            this.$refs.trailerPopVue.switchShow();
        },
        //操作
        homeOperation(index){
            console.log('数据来了',index)
            switch (index) {
                case '1':     //补充信息
                    this.$refs.callPoliceVue.switchShow(3);
                    break;
                case '2':     //去签字
                    this.$refs.signatureVue.switchShow();
                    break;
                case '3':     //去评价
                    this.$refs.evaluationVue.switchShow();
                    break;
                case '4':     //查看详情
                    this.$refs.viewDetailsVue.switchShow();
                    break;
            }
        },
        palyVideo(src){
            this.videoSrc = src;
            this.is_playVideo = true;
            if(this.myPlayer){  
                this.myPlayer.src(src);
                return;
            }
            this.myPlayer = this.$video('myVideo', {
                controls: true,
                autoplay: true,
                // preload: "auto",
                controlBar:{
                    children: [
                        {name: 'playToggle'}, // 播放按钮
                        {name: 'currentTimeDisplay'}, // 当前已播放时间
                        {name: 'progressControl'}, // 播放进度条
                        {name: 'durationDisplay'}, // 总时间
                        {
                            name: 'volumePanel', // 音量控制
                            inline: false, // 不使用水平方式
                        },
                        {name: 'FullscreenToggle'} // 全屏
                    ]
                },
                sources: [{src}]
            });
        },
        closeVideo(){
            console.log('进来了',this.myPlayer)
            this.is_playVideo = false;
            this.myPlayer.pause();
        },
        bindSafetyTypeIndex(id){
            this.safetyTypeIndex = id;
        },
        OpenDialog(){
            this.$refs.chatWindowVue.switchShow()
        },
        //一键报警
        callButton(){
            this.$refs.callPoliceVue.switchShow();
        },
        selectTab(index){
            this.tabsIndex = index;
        },
        initVideo() {
            //初始化视频方法 循环列表获取每个视频的id

        },
    },
}
</script>
<style  lang="less">
    .home{
        background-color: #f4f4f4;
        padding-bottom: 50px;
    }
    // 视频窗口
    .video_div{
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 99;
        align-items: center;
        justify-content: center;
        >div{
            width: 750px;
            height: 468px;

        }
    }
    // 安全教育
    .education{
        padding: 25px 32px;
    }
    .flex-List{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .safetyType{
        display: inline-block;
        font-size: 28px;
        color: #666666;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 400;
        padding: 18px 26px;
        border-radius: 32px;
        background: #FFF;
        margin-bottom: 24px;
    }
    .safetyType-act{
        color: #FFF;
        background: #668BFF;
    }
    //安全教育视频
    .videoList{
        position: relative;
        width: 335px;
        height: 208px;
        margin-bottom: 16px;
        .video-js{
            height: 100%;
            width: 100%;
            background-image: linear-gradient(0deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.00) 32%);
            border-radius: 8px;
            .vjs-big-play-button{
                height: 100%;
                width: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(43, 51, 63, 0);
            }
            .vjs-big-play-button .vjs-icon-placeholder:before {
                content: "";
            }
        }
    }
    .videoTime{
        position: absolute;
        z-index: 9;
        font-size: 0.32rem;
        color: #FFFFFF;
        letter-spacing: 0;
        font-weight: 400;
        bottom: 20px;
        right: 20px;
    }
    // 拖车服务选择条
    .insureType{
        width: 686px;
        margin: 25px 32px 0 32px;
    }
    .insureType .el-input__inner{
        background-color: #FFF;
        height: 88px;
    }
    .el-select-dropdown__item{
        font-size: 26px;
        height: 80px;
        line-height: 80px;
    }
    .el-icon-arrow-up:before {
        content: "\e790";
    }
    .insureType .el-input{
        .el-select__caret{ 
            font-size: 45px;
        }
        .el-input__icon{
            line-height: 90px;
        }
    }
    .insureType .el-select .el-input.is-focus .el-input__inner{
        border-color: #668BFF;
    }

    // 选项卡
    .tabs{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 96px;
        font-size: 32px;
        color: #666666;
        letter-spacing: 0;
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        background-color: #FFF;
    }
    .tabs-div-act{
        color: #668BFF;
    }
    .tabs-act{
        width: 64px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        height: 6px;
        list-style: none; 
        border-radius: 3px;
        background: #668BFF;
        transition: transform .3s cubic-bezier(.645,.045,.355,1);
    }
    .tabs-act1{ transform: translateX(90px); }
    .tabs-act2{ transform: translateX(340px); }
    .tabs-act3{ transform: translateX(590px); }
    // 选项卡内容
    .tabs-content{
        min-height: 800px;
    }
    // 首页背景
    .home-title-background{
        background-image: url('../assets/img/bg_home.png');
        background-size: 750px 350px;
        position: relative;
        width: 750px;
        height: 350px;
        z-index: 0;
    }
    .customer{
        position: fixed;
        z-index: 99;
        height: 96px;
        width: 96px;
        top: 160px;
        right: 24px;
    }
    .switchType{
        position: absolute;
        z-index: 1;
        line-height: 68px;
        padding: 5px 15px;
        top: 190px;
        left: 24px;
        background-color: #FFF;
        border-radius: 50px;
        font-size: 26px;
        color: #666666;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 400;
        display: flex;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
        }
    }
    // 一键报警
    .home-call{
        position: relative;
        z-index: 1;
        width: 686px;
        // height: 245px;
        margin: -153px auto 0 auto;
        padding-bottom: 40px;
        background-color: #FFF;
        border-radius: 16px 16px 0 0;
    }
    .callButton{
        width: 606px;
        height: 88px;
        margin: 48px 40px 32px 40px;
        background: #ED4D58;
        border-radius: 8px;
        font-family: PingFangSC-Semibold;
        font-size: 32px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 32px;
        font-weight: 600;
    }
    .callButton:focus, .callButton:hover {
        color: #FFF;
        border-color: #f3636c;
        background-color: #f3636c;
    }
    .callHint{
        width: 606px;
        margin: auto;
        display: flex;
        align-items: center;
        img{
            height: 44px;
            width: 44px;
        }
        div{
            width: 542px;
            font-size: 28px;
            color: #666666;
            letter-spacing: 0;
            line-height: 28px;
            font-weight: 400;
            padding-left: 10px;
        }
    }
    // 底部提示
    .manage-interests{
        font-size: 24px;
        color: #B6B6B6;
        letter-spacing: 0;
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        margin-top: 40px;
    }
</style>