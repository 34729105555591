<template>
    <div class="Eevaluation" v-if="is_evaluation">
        <div class="Eevaluation-content">
            <div class="page-title border-bottom">
                用户评价
                <img @click="switchShow" src="../assets/img/nav_closepage.png" alt="" srcset="">
            </div>
            <div class="div-slide">
                <div v-for="item,index in evaluateType"
                    class="Eevaluation-title"
                    :key="index">
                    <div>{{item.TypeName}}</div>
                    <star :score="item.star" />
                </div>
                <el-input
                    type="textarea"
                    class="Eevaluation-textarea"
                    placeholder="请输入文字.."
                    v-model="textarea"
                >
                </el-input>
                <el-button 
                    type="primary" 
                    class="callPoliceButton"
                >确定</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import star from "./common/Star.vue";

export default {
    name:'evaluation',
    components:{star},
    data() {
        return {
            is_evaluation:false,
            evaluateType:[
                {
                    TypeName:'平台事故处理效能',
                    star:0,
                },
                {
                    TypeName:'保险理赔响应速度',
                    star:0,
                },
                {
                    TypeName:'保险理赔服务态度',
                    star:0,
                }
            ]
        }
    },
    methods:{
        switchShow(){
            this.is_evaluation = !this.is_evaluation;
        }
    }
}
</script>
<style scoped lang="less">
    .Eevaluation-textarea /deep/ .el-textarea__inner{
        background: #F4F4F4;
        border-radius: 16px;
        width: 686px;
        margin: 64px 32px;
    }
    .Eevaluation{
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        z-index: 99;
        .Eevaluation-content{
            background-color: #FFF;
            border-radius: 32px 32px 0 0;
            width: 750px;
            margin-top: auto;

        }
    }
    .Eevaluation-title{
        padding: 0 30px;
        >div:nth-child(1){
            margin-top: 55px;
            font-size: 32px;
            color: #333333;
            letter-spacing: 0;
            line-height: 32px;
            font-weight: 400;
            margin-bottom: 32px;
        }
    }
</style>