<template>
    <div v-if="is_signature">
        <div class="signature">
            <div class="signature-content">
                <div class="page-title border-bottom">
                    {{is_audit?'签名':'等待审核'}}
                    <img @click="switchShow" src="../assets/img/nav_closepage.png" alt="" srcset="">
                </div>
                <div class="div-slide">
                    <div class="signature-info">
                        <div>事故认定正文</div>
                        <div v-if="is_audit">
                            <div>2021年05月31号17时23分，张三(驾驶证号：xxxxxxxxxxxxxxxxxx )驾驶车牌号为浙Fxxxxxx小型汽车，在浙江省嘉兴市南湖区中山东路1156号与李四( 驾驶证号： xxxxxxxxxxxxxxxxxx )驾驶车牌号为浙Fxxxxxx小型汽车发生两车损坏的交通事故。</div>
                            <div>当事人张三在同车道行驶中，不按规定与前车保持必要距离的，负全部责任：当事人李四无责任。</div>
                        </div>
                    </div>
                    <div v-if="!is_audit" class="signature-await">
                        <div>
                            <img src="../assets/img/audit.png" alt="">
                            <div>等待交警审核处理</div>
                        </div>
                    </div>
                    
                    <div class="signature-div" v-if="is_audit">
                        <span class="dialog-footer">
                            <div @click="handleReset">清空</div>
                        </span>
                        <vue-esign
                            ref="esign"
                            class="mySign"
                            :width="800"
                            :height="380"
                            lineWidth="2"
                        />
                    </div>
                    <el-button 
                        :disabled="!is_audit"
                        type="primary" 
                        @click="handleGenerate"
                        class="callPoliceButton"
                    >确定</el-button>
                </div>
            </div>
        </div>    
        <!-- 弹窗 -->
        <div class="signature_pop" v-if="is_signature_pop">
            <div class="signature_pop_content">
                <div>保险直赔</div>
                <div>本人同意事故受损车辆以实物赔付的形式进行修复，并同意前往保险公司委托的修理厂进行修理。</div>
                <div @click="signaturePop()">
                    <div>不同意</div>
                    <div>同意</div>
                </div>
            </div>
        </div>    
    </div>

</template>
<script>
export default {
    name:'signature',
    data() {
        return {        
            is_signature:false,    //是否显示审核模块
            is_audit:false,       //审核是否通过
            is_signature_pop:false,//是否显示弹窗

            bgColor: "", // 画布的背景颜色
            resultImg: "", // 最终画布生成的base64图片
            isCrop: false, // 是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
        }
    },
    
    methods:{
        
        // 清空画板
        handleReset() {
            this.$refs.esign.reset();
        },
        // 生成签字图
        handleGenerate() {
            this.$refs.esign
            .generate()
            .then((res) => {
            this.resultImg = res;
            })
            .catch((err) => {
            this.$message({
                type: "warning",
                message: "请签名后再生成签字图片",
            });
            });
            setTimeout(() => {
                console.log('我是签字后的base64图片',this.resultImg);
            }, 200);
        },
        signaturePop(){
            if(this.is_signature_pop){
                this.is_audit = true;
            }
            this.is_signature_pop = !this.is_signature_pop;
        },
        switchShow(){
            this.is_signature = !this.is_signature;
            if(this.is_signature){
                setTimeout(()=>{
                    this.is_signature_pop = true;
                },1000)
            }
        },
    }
}
</script>
<style lang="less">
    //等待审核提醒
    .signature-await{
        width: 100%;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        >div{
            img{
                width: 160px;
                height: 160px;
            }
            div{
                margin-top: 24px;
                font-size: 32px;
                color: #333333;
                letter-spacing: 0;
                line-height: 32px;
                font-weight: 600;
            }
        }
    }
    // 弹窗
    .signature_pop{
        position: fixed;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        text-align: center;
        background: rgba(0, 0, 0, 0.55);
        .signature_pop_content{
            width: 526px;
            padding: 48px;
            background-color: #FFF;
            border-radius: 16px;
            >div:nth-child(1){
                margin:32px 0;
                font-size: 36px;
                color: #333333;
                font-weight: 600;
            }
            >div:nth-child(2){
                font-size: 28px;
                color: #666666;
                letter-spacing: 0;
                line-height: 40px;
                font-weight: 400;
                min-height: 200px;
                text-align: left;
            }
            >div:nth-child(3){
                display: flex;
                justify-content: space-between;
                font-size: 32px;
                letter-spacing: 0;
                line-height: 32px;
                font-weight: 400;
                >div{
                    width: 247px;
                    height: 88px;
                    text-align: center;
                    line-height: 88px;
                    border-radius: 8px;
                }
                >div:nth-child(1){
                    background: #ebf0ff;
                    color: #668BFF;
                }
                >div:nth-child(2){
                    background: #668BFF;
                    color: #FFF;
                }
            }
        }
    }
    .signature{
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        z-index: 99;
        .signature-content{
            background-color: #FFF;
            border-radius: 32px 32px 0 0;
            width: 750px;
            margin-top: auto;

        }
    }
    .signature-info{
        width: 750px;
        >div:nth-child(1){
            font-size: 32px;
            color: #333333;
            letter-spacing: 0;
            line-height: 32px;
            font-weight: 600;
            margin: 47px 32px;
        }
        >div:nth-child(2){
            font-size: 32px;
            color: #333333;
            letter-spacing: 0;
            line-height: 40px;
            font-weight: 400;
            margin: 0 35px;
            >div{
                text-indent:2em;
            }
        }
    }
    // 标题
    .page-title{
        position: relative;
        height: 104px;
        font-size: 36px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 104px;
        font-weight: 600;
        >img{
            height: 44px;
            width: 44px;
            top: 30px;
            right: 30px;
            position: absolute;
        }
    }
    //签字区域
    .signature-div{
            width: 685px;
            height: 436px;
            margin: 30px auto;
        span{
            height: 88px;
            text-align: right;
            font-size: 32px;
            color: #668BFF;
            letter-spacing: 0;
            line-height: 88px;
            font-weight: 400;
        }
        .mySign{
            border: 1px solid #CCCCCC;
            border-radius: 16px;
        }
    }
    //按钮样式
    .callPoliceButton{
        width: 750px;
        height: 104px;
        // background: #ED4D58;
        font-size: 36px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
        border-radius: 0;
    }
    .border-bottom{
        border-bottom: 0.5px solid #E4E4E4;
    }
</style>