<template>
    <div class="traileritem">
        <div>{{item.companyName}}</div>
        <div>{{item.companyIhpone}}</div>
        <div>
            <div class="blockLabel">{{item.insuranceType}}</div>
            <div @click="dialPhone">
                <span>{{item.companyTel}}</span>
                <img src="../assets/img/phonenumber.png" alt="" srcset="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'traileritem',
    props:['item','dial_Phone'],
    data() {
        return {
            
        }
    },
    methods:{
        dialPhone(){
            this.dial_Phone(this.item.companyTel)
        }
    }
}
</script>
<style scoped lang="less">
    .traileritem{
        width: 686px;
        height: 264px;
        background: #FFFFFF;
        border-radius: 16px;
        margin: 24px auto 0 auto;
        padding: 30px;
        box-sizing: border-box;
        >div:nth-child(1){
            font-size: 32px;
            color: #333333;
            letter-spacing: 0;
            line-height: 36px;
            font-weight: 600;
        }
        >div:nth-child(2){
            margin: 24px 0;
            font-size: 28px;
            min-height: 70px;
            color: #666666;
            letter-spacing: 0;
            line-height: 36px;
            font-weight: 400;
        }
        >div:nth-child(3){
            display: flex;
            height: 56px;
            .blockLabel{
                font-size: 28px;
                color: #999999;
                letter-spacing: 0;
                line-height: 28px;
                font-weight: 400;
                padding: 14px 24px;
                background: #F4F4F4;
                border-radius: 28px;
            }
            >div:nth-child(2){
                flex: 1;
                text-align: right;
                line-height: 56px;
            }
            span{
                vertical-align: middle;
                font-size: 28px;
                color: #668BFF;
                letter-spacing: 0;
                text-align: right;
                line-height: 28px;
                font-weight: 400;
                margin-right: 14px;
            }
            img{
                vertical-align: middle;
                width: 48px;
                height: 48px;
            }
        }
    }
</style>