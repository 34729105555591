<template>
    <div>
        <div class="baiduMap">
            <i-form :model="center" :label-width="100">
                <Form-item label="经度">
                    <i-input :value.sync="center.lng"></i-input>
                </Form-item>
                <Form-item label="纬度">
                    <i-input :value.sync="center.lat"></i-input>
                </Form-item>
                <i-button type="primary">提交</i-button>
            </i-form>

            <baidu-map
                class="bm-view"
                :center="center"
                :zoom="12"
                :double-click-zoom="true"
                :pinch-to-zoom="true"
                @click="getClickInfo"
                @ready="handler"
                @moving="syncCenterAndZoom"
                @moveend="syncCenterAndZoom"
                @dragend="dragend"
                @zoomend="syncCenterAndZoom">
                <bm-view style="width: 100%; height:500px;"></bm-view>
                <bm-marker
                :position="{ lng: center.lng, lat: center.lat }"
                :dragging="true"
                animation="BMAP_ANIMATION_BOUNCE">
                </bm-marker>
    <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                <bm-local-search
                　:keyword="keyword"
                　:auto-viewport="true"
                　style="width:0px;height:0px;overflow: hidden;"
                ></bm-local-search>
                 <!-- <bm-control :offset="{ width: '10px', height: '10px' }">
                    <bm-auto-complete v-model="keyword" :sugStyle="{ zIndex: 999999 }">
                        <input
                        type="text"
                        placeholder="请输入搜索关键字"
                        class="serachinput"/>
                    </bm-auto-complete>
                </bm-control> -->
            </baidu-map>
            
        </div>
    </div>
</template>
 

<script>
//地图组件---按需引入
    import {
        BaiduMap,
        BmView,
        BmLocalSearch,
        BmMarker
    } from "vue-baidu-map";
 
export default {
    components: {
        BaiduMap,
        BmView,
        BmLocalSearch,
        BmMarker
    },
    data: function() {
        return {
            showMapComponent: this.value,
            keyword: "",
            mapStyle: {
                width: "100%",
                height: this.mapHeight + "px"
            },
            center: {
                lng: 0,
                lat: 0
            },
            zoom:15,
            BMap:null,
            map:null,
        };
    },
    watch: {
        value: function(currentValue) {
                this.showMapComponent = currentValue;
            if (currentValue) {
                this.keyword = "";
            }
        }
    },
    methods: {
        handler({ BMap, map }){
            const that = this;
            var geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition(function(r) {
                console.log('数据',r)
                that.center.lng = r.longitude;
                that.center.lat = r.latitude;
            });
            this.BMap = BMap;
            this.map = map;
        },
        dragend (e) {
            this.position = e.point
            const _this = this
            const gc = new this.BMap.Geocoder()
            gc.getLocation(e.point, function (rs) {
                console.log(rs)
                _this.addr = rs.address
            })
        },
        getClickInfo(e) {
            const _this = this
            const gc = new this.BMap.Geocoder()
            gc.getLocation(e.point, function (rs) {
                console.log(rs)
                _this.addr = rs.address
            })
            this.center.lng = e.point.lng;
            this.center.lat = e.point.lat;
        },
        syncCenterAndZoom(e) {
            const { lng, lat } = e.target.getCenter();
            this.center.lng = lng;
            this.center.lat = lat;
            this.zoom = e.target.getZoom();
        },

    }
};
</script>
　
<style scoped>
.head {
margin-left: 200px;
width: 100px;
}
.baiduMap {
width: 80%;
height: 800px;
margin: 0 auto;
overflow: hidden;
}
.ivu-form-item {
display: inline-block;
width: 40%;
}
.bm-view > div {
width: 100%;
height: 700px !important;
}
.serachinput {
    width: 300px;
    box-sizing: border-box;
    padding: 9px;
    border: 1px solid #dddee1;
    line-height: 20px;
    font-size: 16px;
    height: 38px;
    color: #333;
    position: relative;
    border-radius: 4px;
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    box-shadow: #666 0px 0px 10px;
}
</style>