<template>
    <div class="TrailerPop" v-if="is_trailerPop">
        <div class="TrailerPop-content">
            <div class="TrailerPop-info">
                <img src="../../assets/img/newstips.png" alt="" srcset="">
                <div>服务说明</div>
                <div>
                    我司95518/查勘人员派单给拖车公司→拖车公司调度工作人员或合作救援方（按就近原则）→到达现场进行施救→拖车至停车场或维修单位→客户支付费用→客户向保险公司提供向救援合作平台帐户（对公帐户）支付记录即可进行理赔；
                </div>
                <div>拖车电话:{{phone_number}}</div>
                <el-button 
                    class="dial"
                    @click="dial"
                    type="primary" 
                >立即拨打</el-button>                
            </div>
            <div class="window-close" @click="switchShow()">
                <img src="../../assets/img/ctivity_tips.png" alt="" srcset="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'trailerPop',
    props:['phone_number'],
    data() {
        return {
            is_trailerPop:false
        }
    },
    methods:{
        switchShow(){
            this.is_trailerPop = !this.is_trailerPop;
        },
        dial(){
            window.location.href = 'tel://' + this.Phone_number
        }
    }
}
</script>
<style scoped lang="less">
    .TrailerPop{
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        z-index: 99;
        align-items: center;
        justify-content: center;
        .TrailerPop-content{
            width: 622px;
            .TrailerPop-info{
                text-align: center;
                padding: 0 48px 48px 48px;
                border-radius: 18px;
                background-color: #FFF;
                >img{
                    height: 120px;
                    width: 120px;
                    margin-top: -70px;
                    border-radius: 50%;
                    background-color: #FFF;
                    border: 19px solid #FFF;
                }
                >div:nth-child(2){
                    margin: 32px 0;
                    font-size: 36px;
                    color: #333333;
                    letter-spacing: 0;
                    font-weight: 600;
                }
                >div:nth-child(3){
                    font-size: 28px;
                    color: #666666;
                    letter-spacing: 0;
                    line-height: 40px;
                    font-weight: 400;
                    min-height: 300px;
                }
                >div:nth-child(4){
                    font-size: 28px;
                    color: #668BFF;
                    letter-spacing: 0;
                    line-height: 40px;
                    font-weight: 400;
                    margin-bottom: 32px;
                }
                .dial{
                    width: 100%;
                    height: 88px;
                    font-size: 32px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 32px;
                    font-weight: 600;
                    background: #668BFF;
                    border-radius: 8px
                }
            }
        }
    }    
    .window-close {
        text-align: center;
        margin-top: 20px;
        img{
            height: 80px;
            width: 80px;
        }
    }
</style>