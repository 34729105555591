<template>
    <div :class="`Reporteditem ReportStatus`+item.report_State">
        <ul>
            <li>
                <div>报案编号:</div>
                <div>{{item.report_Number}}</div>
                <div>{{ReportStatus}}</div>
            </li>
            <li>
                <div>事故类型:</div>
                <div>{{item.accident_Type}}</div>
            </li>
            <li>
                <div>报警时间:</div>
                <div>{{item.police_Time}}</div>
            </li>
            <li>
                <div>事故地点:</div>
                <div>{{item.accident_Site}}</div>
                <div>
                    <div @click="operation()">{{operate}}</div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props:["item","homeOperation"],
    data() {
        return {
            
        }
    },
    methods:{
        operation(){
            this.homeOperation(this.item.report_State)
        }
    },
    computed:{
        ReportStatus(){
            let _report = '';
            switch (this.$props.item.report_State){
                case '1': _report = '报案成功'; break;
                case '2': _report = '待签字'; break;
                case '3': _report = '待评价'; break;
                case '4': _report = '已完成'; break;
            }
            return _report
        },
        operate(){
            let _operate = '';
            switch (this.$props.item.report_State){
                case '1': _operate = '补充信息'; break;
                case '2': _operate = '去签字'; break;
                case '3': _operate = '去评价'; break;
                case '4': _operate = '查看详情'; break;
            }
            return _operate
        }
    }
}
</script>
<style scoped lang="less">
    .Reporteditem{
        width: 686px;
        background: #FFFFFF;
        margin: 25px auto 0 auto;
        border-radius: 0 16px 16px 0;
        border-left: 8px solid #59C98A;
        ul{
            padding: 40px;
            padding-bottom: 16px;
            font-size: 28px;
            letter-spacing: 0;
            line-height: 28px;
            li{
                display: flex;
                margin-bottom: 24px;
                >div:nth-child(1){
                    width: 140px;
                    font-weight: 600;
                    color: #333333;
                    display: inline-block;
                }
                >div:nth-child(2){
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    width: 305px;
                    color: #666666;
                }
                >div:nth-child(3){
                    color: #333333;
                    text-align: right;
                    width: 165px;
                    >div{
                        text-align: center;
                        line-height: 56px;
                        margin-left: 40px;
                        width: 136px;
                        height: 56px;
                        border-radius: 8px;
                        color: #668BFF;
                        border: 1px solid #668BFF;
                    }
                }
            }
        }
    }

    .ReportStatus1{
        border-left: 8px solid #59C98A;
        ul{
            li{
                >div:nth-child(3){
                    color: #59C98A;
                }
            }
        }
    }


    .ReportStatus2{
        border-left: 8px solid #F67777;
        ul{
            li{
                >div:nth-child(3){
                    color: #F67777;
                }
            }
        }
    }

    .ReportStatus3{
        border-left: 8px solid #668BFF;
        ul{
            li{
                >div:nth-child(3){
                    color: #668BFF;
                }
            }
        }
    }

    .ReportStatus4{
        border-left: 8px solid #979BA3;
        ul{
            li{
                >div:nth-child(3){
                    color: #979BA3;
                }
            }
        }
    }
</style>