<template>
	<div class="chatwindow" v-if="isShow_chatWindow">
		<div class="bts" v-if="call_type == 3"></div>
		<div class="usth" v-if="call_type == 3">
			<div class="fix"></div>
			<div id="local_stream" class="usvideo" @click="btns" :class="{ on: usvideo == 1 }"></div>
			<div id="remote_stream" class="usvideo" @click="btns" :class="{ on: usvideo == 2 }"></div>
			<div class="cents">
				<div class="name" v-if="!pics"><img src="../assets/img/service_head.png" alt="" /></div>
				<div class="h2" v-if="!pics">{{ call_show }}</div>
				<div class="btn">
					<el-button @click="qiehuan" class="uubtn uubtn1"><img src="../assets/img/uuico3.png" alt="" /></el-button>
					<el-button v-if="!call_s" @click="jssptjbtn" class="uubtn"><img src="../assets/img/uuico2.png" alt="" /></el-button>
					<el-button @click="jsspbtn" class="uubtn"><img src="../assets/img/uuico1.png" alt="" /></el-button>
				</div>
			</div>
		</div>
		<div class="chat-content" v-if="call_type == 2">
			<div v-if="!call_s">{{ call_show }}</div>
			<div v-else>已接听</div>
			<el-button @click="jsyptjbtn" class="transmit-button">接听通话</el-button>
			<el-button @click="jsypbtn" class="transmit-button">结束通话</el-button>
		</div>
		<div class="chat-content" v-if="call_type == 1">
			<div class="chat-content-title">嘉兴交警 | 二大队</div>
			<div class="chat-dialogue" ref="scrollDiv">
				<div class="chat-dialogue-item" :class="item.type == 'me' ? 'chat-right' : 'chat-laft'" v-for="(item, index) in dialogue_data" :key="index">
					<img v-if="item.type == 'you'" src="../assets/img/service_head.png" alt="" />
					<div>
						<template v-if="item.photograph">
							<img v-for="(p, i) in item.photograph" :key="i" :src="p.imageUrl" alt="" />
						</template>
						{{ item.message }}
					</div>
					<img v-if="item.type == 'me'" src="../assets/img/user_head.png" alt="" />
				</div>
			</div>

			<div class="chat-input">
				<div class="transmit">
					<div class="uploadImg">
						<!-- <el-upload
							action="https://jsonplaceholder.typicode.com/posts/"
							list-type="picture-card"
							accept=".jpg,.png"
							:limit="3"
							:on-preview="handlePictureCardPreview"
							:on-remove="handleRemove"
						>
							<img class="photograph" src="../assets/img/photograph.png" alt="" srcset="" />
						</el-upload>
						<el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog> -->
						<input type="file" id="filed" hidden="" style="width: 20px;" @change="getPic" />
						<i class="el-icon-camera" style="font-size: 25px;width: 40px;" @click="sendpic"></i>
						<i class="el-icon-phone-outline" style="font-size: 25px;width: 40px;" @click="sendyy"></i>
						<i class="el-icon-video-camera" style="font-size: 25px;width: 40px;" @click="sendsp"></i>
					</div>
					<el-button v-if="textarea" @click="transmitEvent" class="transmit-button">发送</el-button>
				</div>
				<el-input type="textarea" placeholder="请输入文字.." v-model="textarea"></el-input>
			</div>

			<div class="window-close" @click="switchShow()"><img src="../assets/img/ctivity_tips.png" alt="" srcset="" /></div>
		</div>
	</div>
</template>
<script>
import TIM from 'tim-js-sdk';
import TRTC from 'trtc-js-sdk';
import TRTCCalling from 'trtc-calling-js';
import TIMUploadPlugin from 'tim-upload-plugin';
let tim;
let client;
let localStream;
let trtcCalling;
export default {
	name: 'chatwindow',
	data() {
		return {
			isShow_chatWindow: false,
			dialogue_data: [
				// {
				// 	type: 'me',
				// 	message: '请问这里是嘉兴事故云处沟通平台吗？',
				// 	image: '../assets/img/user_head.png',
				// 	photograph: null
				// },
				// {
				// 	type: 'you',
				// 	message: '是的，您需要什么帮助？',
				// 	image: '../assets/img/service_head.png',
				// 	photograph: null
				// }
			],
			usvideo: 1,
			textarea: '',
			dialogImageUrl: '',
			dialogVisible: false,
			fileArr: '',
			userId: '',
			toAccid: '',
			userSig: '',
			sdkappID: '',
			call_type: 1, //1图文 2音频 3视频
			call_show: '接听中请稍等',
			call_s: 0,
			pics: 0,
			sxt:0
			
		};
	},
	mounted() {
		let that = this;
		that.getLogin();
	},
	methods: {
		qiehuan() {
			let that = this;
			trtcCalling.getCameras().then(devices => {
				console.log(devices);
				if(devices[1]!==undefined&&that.sxt==0){
					trtcCalling.switchDevice({deviceType: 'video', deviceId: devices[1].deviceId}) // 切换设备
				}
				if(devices[1]!==undefined&&that.sxt==1){
					trtcCalling.switchDevice({deviceType: 'video', deviceId: devices[0].deviceId}) // 切换设备
				}
			}); // 获取摄像头列表
		},
		btns() {
			let that = this;
			if (that.usvideo == 1) {
				that.usvideo = 2;
			} else {
				that.usvideo = 1;
			}
		},
		jsspbtn() {
			let that = this;
			trtcCalling.hangup();
			that.call_type = 1;
			that.pics = 0;
		},
		jssptjbtn() {
			let that = this;
			trtcCalling.accept();
			that.call_s = 1;
			that.pics = 1;
		},
		jsypbtn() {
			let that = this;
			trtcCalling.hangup();
			that.call_type = 1;
		},
		jsyptjbtn() {
			let that = this;
			trtcCalling.accept();
			that.call_s = 1;
		},
		sendyy() {
			let that = this;
			that.call_type = 2;
			let offlinePushInfo = {
				title: '',
				description: '您有一个通话请求',
				extension: ''
			};
			let promise = trtcCalling.call({ userID: that.toAccid, type: 1, offlinePushInfo: offlinePushInfo });

			promise
				.then(res => {
					console.log(res, 123123);
					//success
				})
				.catch(error => {
					console.warn('call error:', error);
				});

			// trtcCalling.startLocalView({
			// 	userID: that.userId, //本地用户 ID
			// 	videoViewDomID: 'local_stream' //该用户数据将渲染到该 DOM ID 节点里
			// });

			// trtcCalling.startRemoteView({
			// 	userID: that.toAccid, //本地用户 ID
			// 	videoViewDomID: 'remote_stream' //该用户数据将渲染到该 DOM ID 节点里
			// });
		},
		sendsp() {
			let that = this;
			that.call_type = 3;
			that.call_s = 1;
			// trtcCalling.call({
			// 	userID: that.toAccid, //用户 ID
			// 	type: 2, //通话类型，0-未知， 1-语音通话，2-视频通话
			// 	timeout: 60 //邀请超时时间, 单位 s(秒)
			// });

			let offlinePushInfo = {
				title: '',
				description: '您有一个通话请求',
				extension: ''
			};
			let promise = trtcCalling.call({ userID: that.toAccid, type: 2, offlinePushInfo: offlinePushInfo });

			promise
				.then(res => {
					console.log(res, 123123);
					//success
				})
				.catch(error => {
					console.warn('call error:', error);
				});

			// localStream = TRTC.createStream({ userId: that.userId, audio: true, video: true });
			// console.log(localStream, 123123);
			// localStream
			// 	.initialize()
			// 	.then(response => {
			// 		console.log('初始化本地流成功');
			// 		// 'local_stream' 是在 DOM 中的一个 div 标签的 ID
			// 		localStream.play('local_stream');
			// 		client
			// 			.join({ roomId: Number(that.toAccid) })
			// 			.then((res) => {
			// 				console.log('进房成功'+res);
			// 				client
			// 					.publish(localStream)
			// 					.then(response => {
			// 						console.log('本地流发布成功'+response);
			// 						var d_data = {
			// 							businessID: 1,
			// 							inviteID: '858938f1-46b4-4383-b3b3-3726dadb9f35',
			// 							inviter: that.userId,
			// 							actionType: 1,
			// 							inviteeList: [that.userId],
			// 							data: {
			// 								extraInfo: '',
			// 								version: 4,
			// 								call_type: 2,
			// 								room_id: Number(that.toAccid),
			// 								businessID: 'av_call',
			// 								platform: 'Web',
			// 								data: {
			// 									cmd: 'videoCall',
			// 									room_id: Number(that.toAccid),
			// 									message: ''
			// 								}
			// 							},
			// 							timeout: 30,
			// 							groupID: ''
			// 						};
			// 						let message = tim.createCustomMessage({
			// 							to: that.toAccid,
			// 							conversationType: TIM.TYPES.CONV_C2C,
			// 							// 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
			// 							// 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
			// 							// priority: TIM.TYPES.MSG_PRIORITY_HIGH,
			// 							payload: {
			// 								data: JSON.stringify(d_data), // 用于标识该消息是骰子类型消息
			// 								description: '', // 获取骰子点数
			// 								extension: ''
			// 							}
			// 						});

			// 						// 2. 发送消息
			// 						let promise = tim.sendMessage(message);
			// 						promise
			// 							.then(function(imResponse) {
			// 								console.log(imResponse);
			// 								// 发送成功
			// 								let obj = {
			// 									type: 'me',
			// 									message: '语言聊天',
			// 									image: '../assets/img/service_head.png'
			// 								};
			// 								that.dialogue_data.push(obj);
			// 								setTimeout(function() {
			// 									var div = that.$refs.scrollDiv;
			// 									console.log(div);
			// 									console.log(div.scrollTop);
			// 									console.log(div.scrollHeight);
			// 									div.scrollTop = div.scrollHeight;
			// 								}, 200);
			// 								that.textarea = '';
			// 							})
			// 							.catch(function(imError) {
			// 								// 发送失败
			// 								console.warn('sendMessage error:', imError);
			// 							});
			// 					})
			// 					.catch(error => {
			// 						console.error('本地流发布失败 ' + error);
			// 					});
			// 			})
			// 			.catch(error => {
			// 				console.error('进房失败 ' + error);
			// 			});
			// 	})
			// 	.catch(error => {
			// 		console.error('初始化本地流失败 ' + error);
			// 	});
		},
		getPic(e) {
			let that = this;

			var files = e.target.files[0];

			let message = tim.createImageMessage({
				to: that.toAccid,
				conversationType: TIM.TYPES.CONV_C2C,
				// 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
				// 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
				// priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
				payload: {
					file: document.getElementById('filed')
				},
				// 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
				// cloudCustomData: 'your cloud custom data'
				onProgress: function(event) {
					console.log('file uploading:', event);
				}
			});

			// 2. 发送消息
			let promise = tim.sendMessage(message);
			promise
				.then(function(imResponse) {
					// 发送成功
					let obj = {
						type: 'me',
						message: '',
						image: '../assets/img/service_head.png',
						photograph: [imResponse.data.message.payload.imageInfoArray[0]],
						msgtype: 'image'
					};
					console.log(obj, 123123);
					that.dialogue_data.push(obj);
					setTimeout(function() {
						var div = that.$refs.scrollDiv;
						div.scrollTop = div.scrollHeight;
					}, 200);
				})
				.catch(function(imError) {
					// 发送失败
					console.warn('sendMessage error:', imError);
				});
			console.log(that.dialogue_data, 333444);
		},
		sendpic() {
			document.getElementById('filed').click();
		},
		async transmitEvent() {
			let that = this;
			//发送内容
			let message = tim.createTextMessage({
				to: that.toAccid,
				conversationType: TIM.TYPES.CONV_C2C,
				// 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
				// 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
				// priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
				payload: {
					text: this.textarea
				}
				// 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
				// cloudCustomData: 'your cloud custom data'
			});
			// 2. 发送消息
			let promise = tim.sendMessage(message);
			promise
				.then(function(imResponse) {
					// 发送成功
					let obj = {
						type: 'me',
						message: that.textarea,
						image: '../assets/img/service_head.png',
						photograph: that.fileArr
					};
					that.dialogue_data.push(obj);
					setTimeout(function() {
						var div = that.$refs.scrollDiv;
						console.log(div);
						console.log(div.scrollTop);
						console.log(div.scrollHeight);
						div.scrollTop = div.scrollHeight;
					}, 200);
					that.textarea = '';
				})
				.catch(function(imError) {
					// 发送失败
					console.warn('sendMessage error:', imError);
				});
		},
		switchShow() {
			this.isShow_chatWindow = !this.isShow_chatWindow;
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePictureCardPreview(file) {
			console.log(file);
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},

		getLogin() {
			let that = this;
			that.$axios
				.post('https://jxjj.info:10005/yjscar/txy/queryIMUserInfo', { code: 1234, phone: 15000620672, longitude: '', latitude: '', type: 2 })
				.then(function(response) {
					console.log(response);
					that.userId = response.data.data.userId;
					that.toAccid = response.data.data.toAccid;
					that.sdkappID = response.data.data.sdkappID;
					that.userSig = response.data.data.userSig;

					that.tim_init();
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		tim_init() {
			let that = this;
			let options = {
				SDKAppID: that.sdkappID // 接入时需要将0替换为您的即时通信应用的 SDKAppID
			};
			tim = TIM.create(options); // SDK 实例通常用 tim 表示
			// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
			tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
			// tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用

			// 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
			tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });

			// 监听事件，如：
			tim.on(TIM.EVENT.SDK_READY, function(event) {
				// 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
				// event.name - TIM.EVENT.SDK_READY
				console.log(event, 1);
			});

			tim.on(TIM.EVENT.MESSAGE_RECEIVED, function(event) {
				// 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
				// event.name - TIM.EVENT.MESSAGE_RECEIVED
				// event.data - 存储 Message 对象的数组 - [Message]
				console.log(event, 2);
				let obj;
				switch (event.data[0].type) {
					//图片
					case 'TIMImageElem':
						obj = {
							type: 'you',
							message: '',
							image: '../assets/img/service_head.png',
							photograph: [event.data[0].payload.imageInfoArray[0]],
							msgtype: 'image'
						};
						console.log(event.data[0].payload.imageInfoArray);
						that.dialogue_data.push(obj);
						setTimeout(function() {
							var div = that.$refs.scrollDiv;
							div.scrollTop = div.scrollHeight;
						}, 200);

						break;
					//文字
					case 'TIMTextElem':
						obj = {
							type: 'you',
							message: event.data[0].payload.text,
							image: '../assets/img/service_head.png',
							photograph: that.fileArr,
							msgtype: 'text'
						};
						that.dialogue_data.push(obj);
						setTimeout(function() {
							var div = that.$refs.scrollDiv;
							div.scrollTop = div.scrollHeight;
						}, 200);

						break;
					//自定义消息
					case 'TIMCustomElem':
						// var dd = JSON.parse(event.data[0].payload.data);
						// var ddd = JSON.parse(dd.data);
						// console.log(dd);
						// console.log(ddd);
						// console.log(ddd.room_id);
						// client
						// 	.join({ roomId: ddd.room_id })
						// 	.then(() => {
						// 		console.log('进房成功');
						// 	})
						// 	.catch(error => {
						// 		console.error('进房失败 ' + error);
						// 	});

						// localStream = TRTC.createStream({ userId: that.userId, audio: true, video: true });
						// localStream
						// 	.initialize()
						// 	.then(() => {
						// 		console.log('初始化本地流成功');
						// 		client
						// 			.publish(localStream)
						// 			.then(() => {
						// 				console.log('本地流发布成功');
						// 			})
						// 			.catch(error => {
						// 				console.error('本地流发布失败 ' + error);
						// 			});
						// 	})
						// 	.catch(error => {
						// 		console.error('初始化本地流失败 ' + error);
						// 	});

						break;
					//视频
					case '123123':
						break;
				}

				let promise = tim.setMessageRead({ conversationID: event.data[0].conversationID });
				promise
					.then(function(imResponse) {})
					.catch(function(imError) {
						// 已读上报失败
						console.warn('setMessageRead error:', imError);
					});
			});
			tim.on(TIM.EVENT.MESSAGE_MODIFIED, function(event) {
				// 收到消息被第三方回调修改的通知，消息发送方可通过遍历 event.data 获取消息列表数据并更新页面上同 ID 消息的内容（v2.12.1起支持）
				// event.name - TIM.EVENT.MESSAGE_MODIFIED
				// event.data - 存储被第三方回调修改过的 Message 对象的数组 - [Message]
				console.log(event, 3);
			});

			tim.on(TIM.EVENT.MESSAGE_REVOKED, function(event) {
				// 收到消息被撤回的通知。使用前需要将SDK版本升级至v2.4.0或更高版本
				// event.name - TIM.EVENT.MESSAGE_REVOKED
				// event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
				console.log(event, 4);
			});

			tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, function(event) {
				// SDK 收到对端已读消息的通知，即已读回执。使用前需要将SDK版本升级至v2.7.0或更高版本。仅支持单聊会话
				// event.name - TIM.EVENT.MESSAGE_READ_BY_PEER
				// event.data - event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
				console.log(event, 5);
			});

			tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, function(event) {
				// 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
				// event.name - TIM.EVENT.CONVERSATION_LIST_UPDATED
				// event.data - 存储 Conversation 对象的数组 - [Conversation]
				console.log(event, 6);
			});

			tim.on(TIM.EVENT.GROUP_LIST_UPDATED, function(event) {
				// 收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面
				// event.name - TIM.EVENT.GROUP_LIST_UPDATED
				// event.data - 存储 Group 对象的数组 - [Group]
				console.log(event, 7);
			});

			tim.on(TIM.EVENT.PROFILE_UPDATED, function(event) {
				// 收到自己或好友的资料变更通知
				// event.name - TIM.EVENT.PROFILE_UPDATED
				// event.data - 存储 Profile 对象的数组 - [Profile]
				console.log(event, 8);
			});

			tim.on(TIM.EVENT.BLACKLIST_UPDATED, function(event) {
				// 收到黑名单列表更新通知
				// event.name - TIM.EVENT.BLACKLIST_UPDATED
				// event.data - 存储 userID 的数组 - [userID]
				console.log(event, 9);
			});

			tim.on(TIM.EVENT.ERROR, function(event) {
				// 收到 SDK 发生错误通知，可以获取错误码和错误信息
				// event.name - TIM.EVENT.ERROR
				// event.data.code - 错误码
				// event.data.message - 错误信息
				console.log(event, 10);
			});

			tim.on(TIM.EVENT.SDK_NOT_READY, function(event) {
				// 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
				// event.name - TIM.EVENT.SDK_NOT_READY
				console.log(event, 11);
			});

			tim.on(TIM.EVENT.KICKED_OUT, function(event) {
				// 收到被踢下线通知
				// event.name - TIM.EVENT.KICKED_OUT
				// event.data.type - 被踢下线的原因，例如 :
				//   - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
				//   - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
				//   - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢（v2.4.0起支持）。
				console.log(event, 12);
			});

			tim.on(TIM.EVENT.NET_STATE_CHANGE, function(event) {
				// 网络状态发生改变（v2.5.0 起支持）
				// event.name - TIM.EVENT.NET_STATE_CHANGE
				// event.data.state 当前网络状态，枚举值及说明如下：
				//   - TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
				//   - TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
				//   - TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
				console.log(event, 13);
			});

			tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, function(event) {
				// 收到好友列表更新通知（v2.13.0起支持）
				// event.name - TIM.EVENT.FRIEND_LIST_UPDATED
				// event.data - 存储 Friend 对象的数组 - [Friend]
				console.log(event, 14);
			});

			tim.on(TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED, function(event) {
				// 收到好友申请列表更新通知（v2.13.0起支持）
				// event.name - TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED
				// friendApplicationList - 好友申请列表 - [FriendApplication]
				// unreadCount - 好友申请的未读数
				// const { friendApplicationList, unreadCount } = event.data;
				// 发送给我的好友申请（即别人申请加我为好友）
				// const applicationSentToMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_TO_ME);
				// 我发送出去的好友申请（即我申请加别人为好友）
				// const applicationSentByMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_BY_ME);
				console.log(event, 15);
			});

			tim.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, function(event) {
				// 收到好友分组列表更新通知（v2.13.0起支持）
				// event.name - TIM.EVENT.FRIEND_GROUP_LIST_UPDATED
				// event.data - 存储 FriendGroup 对象的数组 - [FriendGroup]
				console.log(event, 16);
			});
			// 开始登录
			tim.login({ userID: that.userId, userSig: that.userSig });
			// client = TRTC.createClient({ mode: 'rtc', sdkAppId: that.sdkappID, userId: that.userId, userSig: that.userSig });
			// client.on('stream-added', event => {
			// 	const remoteStream = event.stream;
			// 	console.log('远端流增加: ' + remoteStream.getId());
			// 	console.log(remoteStream);
			// 	//订阅远端流
			// 	client.subscribe(remoteStream);
			// });
			// client.on('stream-subscribed', event => {
			// 	const remoteStream = event.stream;
			// 	console.log('远端流订阅成功：' + remoteStream.getId());
			// 	// 播放远端流
			// 	remoteStream.play('remote_stream');
			// });

			let oop = {
				SDKAppID: that.sdkappID, // 接入时需要将0替换为您的 SDKAppID
				// 从v0.10.2起，新增 tim 参数
				// tim 参数适用于业务中已存在 TIM 实例，为保证 TIM 实例唯一性
				tim: tim
			};
			trtcCalling = new TRTCCalling(oop);
			trtcCalling.login({
				userID: that.userId,
				userSig: that.userSig
			});

			//加入通话
			trtcCalling.on(TRTCCalling.EVENT.INVITED, ({ inviteID, sponsor, inviteData }) => {
				console.log(inviteData, 101010);
				if (inviteData.callType == 1) {
					that.call_type = 2;
				}
				if (inviteData.callType == 2) {
					that.call_type = 3;
				}
				// 接听
				// trtcCalling.accept();
				// 拒绝接听
				// trtcCalling.reject();
			});
			//同意通话
			trtcCalling.on(TRTCCalling.EVENT.USER_ENTER, function(event) {
				console.log(event, 909090);
				that.pics = 1;
				that.call_s = 1;
				that.pics = 1;
				if (that.call_type == 3) {
					let promise1 = trtcCalling.startLocalView({ userID: that.userId, videoViewDomID: 'local_stream' });
					promise1
						.then(() => {
							//success
						})
						.catch(error => {
							console.warn('startLocalView error:', error);
						});
					let promise2 = trtcCalling.startRemoteView({ userID: that.toAccid, videoViewDomID: 'remote_stream' });
					promise2
						.then(() => {
							//success
						})
						.catch(error => {
							console.warn('startLocalView error:', error);
						});
				}
			});
			//拒绝通话
			trtcCalling.on(TRTCCalling.EVENT.REJECT, function(event) {
				// 发送成功
				that.call_type = 1;
				that.call_s = 0;
				that.pics = 0;
				trtcCalling.hangup();
				let obj = {
					type: 'me',
					message: '对方已拒绝',
					image: '../assets/img/service_head.png',
					photograph: that.fileArr
				};
				that.dialogue_data.push(obj);
				setTimeout(function() {
					var div = that.$refs.scrollDiv;
					console.log(div);
					console.log(div.scrollTop);
					console.log(div.scrollHeight);
					div.scrollTop = div.scrollHeight;
				}, 200);
			});
			trtcCalling.on(TRTCCalling.EVENT.NO_RESP, function(event) {
				that.call_type = 1;

				trtcCalling.hangup();
				let obj = {
					type: 'me',
					message: '对方无应答',
					image: '../assets/img/service_head.png',
					photograph: that.fileArr
				};
				that.dialogue_data.push(obj);
				setTimeout(function() {
					var div = that.$refs.scrollDiv;
					console.log(div);
					console.log(div.scrollTop);
					console.log(div.scrollHeight);
					div.scrollTop = div.scrollHeight;
				}, 200);
			});

			trtcCalling.on(TRTCCalling.EVENT.LINE_BUSY, function(event) {
				that.call_type = 1;

				trtcCalling.hangup();
				let obj = {
					type: 'me',
					message: '对方繁忙',
					image: '../assets/img/service_head.png',
					photograph: that.fileArr
				};
				that.dialogue_data.push(obj);
				setTimeout(function() {
					var div = that.$refs.scrollDiv;
					console.log(div);
					console.log(div.scrollTop);
					console.log(div.scrollHeight);
					div.scrollTop = div.scrollHeight;
				}, 200);
			});
			//通话结束
			trtcCalling.on(TRTCCalling.EVENT.CALLING_END, function(event) {
				that.call_type = 1;
				that.call_s = 0;
				that.pics = 0;
				trtcCalling.hangup();
				let tt = event.callEnd;
				var h = 0,
					m = 0,
					s = 0;

				m = Math.floor(tt / 60);
				if (m > 60) {
					m = m % 60;
				}
				h = Math.floor(tt / 3600);
				s = tt - h * 360 - m * 60;
				if (s < 10) {
					s = '0' + s;
				}
				if (m < 10) {
					m = '0' + m;
				}
				if (h < 10) {
					h = '0' + h;
				}

				let obj = {
					type: 'me',
					message: '通话结束，通话时长：' + h + ':' + m + ':' + s,
					image: '../assets/img/service_head.png',
					photograph: that.fileArr
				};
				that.dialogue_data.push(obj);
				setTimeout(function() {
					var div = that.$refs.scrollDiv;
					console.log(div);
					console.log(div.scrollTop);
					console.log(div.scrollHeight);
					div.scrollTop = div.scrollHeight;
				}, 200);

				console.log(event, 99999999);
			});
		}
	}
};
</script>
<style>
.bts {
	background: url(../assets/img/bg_home.png) no-repeat center/cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.usvideo {
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1;
}
.fix {
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 2;
}
.usvideo.on {
	left: auto;
	right: 3%;
	top: 5%;
	width: 35%;
	height: 30vh;
	z-index: 11;
}
.usth {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 11;
}
.usth .cents {
	position: absolute;
	left: 0;
	right: 0;
	top: 20%;
	z-index: 11;
	font-size: 26px;
	color: #fff;
	text-align: center;
}
.usth .cents .name {
	width: 180px;
	margin: 0 auto;
}
.usth .cents .h2 {
	font-size: 30px;
	margin: 30px;
}
.usth .cents .btn {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 100px;
	text-align: center;
	border: none;
}
.usth .cents .btn .uubtn {
	width: 140px;
	height: 140px;
	padding: 0;
	background: none;
	border: none !important;
	margin: 0 30px;
	border-radius: 50%;
	vertical-align: top;
}
.usth .cents .btn .uubtn1 {
	background: rgba(0, 0, 0, 0.7);
}
.usth .cents .btn .uubtn1 img {
	width: 50%;
	display: block;
	margin: auto;
}
.usth .cents .btn .uubtn::after {
	display: none;
}
.usth img {
	width: 100%;
	display: block;
}
</style>
<style lang="less">
.chat-input {
	background-color: #fff;
}
.window-close {
	text-align: center;
	margin-top: 20px;
	img {
		height: 80px;
		width: 80px;
	}
}
.transmit {
	display: flex;
	padding: 32px;
	padding-bottom: 0px;
	background-color: #fff;
	.uploadImg {
		flex: 1;
		height: 60px;
	}
	.transmit-button {
		width: 150px;
		height: 60px;
		background-color: #409eff;
		color: #fff;
		padding: 0;
	}
}
.el-textarea__inner {
	border: none;
	padding: 32px;
	height: 230px;
	font-size: 32px;
	letter-spacing: 0;
	line-height: 32px;
	font-weight: 400;
	outline: none;
}
.el-textarea__inner :focus {
	outline: 0;
}
.uploadImg .el-upload--picture-card {
	width: 48px;
	height: 48px;
	border: none;
	background-color: initial;
	line-height: 48px;
}
.uploadImg .el-upload-list--picture-card .el-upload-list__item,
.photograph {
	margin-top: 6px;
	width: 48px;
	height: 48px;
}

.chatwindow {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.55);
	overflow: auto;
	z-index: 2000;
}
.chat-content {
	width: 670px;
	border-radius: 16px;
	overflow: hidden;
}
.chat-content .uubtn {
	display: inline-block;
	width: 160px;
}
.chat-content-title {
	height: 88px;
	line-height: 88px;
	font-size: 32px;
	color: #ffffff;
	letter-spacing: 0;
	font-weight: 600;
	background: #668bff;
	padding: 0 40px;
}
.chat-dialogue {
	height: 600px;
	overflow: auto;
	padding-bottom: 40px;
	background: #f4f4f4;
	z-index: 1;
}
.chat-dialogue-item {
	display: flex;
	padding: 40px 40px 0 40px;
	> div {
		font-size: 32px;
		color: #111111;
		letter-spacing: 0;
		line-height: 42px;
		font-weight: 400;
		border-radius: 8px;
		padding: 24px;
		position: relative;
		> img {
			max-width: 413px;
		}
	}
	> img {
		height: 88px;
		width: 88px;
	}
}
.chat-right {
	justify-content: flex-end;
	> div {
		margin: 0 10px;
		background: #d9e2ff;
	}
	> div:after {
		content: '';
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		border-left: 15px solid #d9e2ff;
		position: absolute;
		top: 20px;
		right: -15px;
	}
	> img {
		margin-left: 20px;
	}
}
.chat-laft {
	> div {
		margin: 0 10px;
		background: #fff;
	}
	> div:after {
		content: '';
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		border-right: 15px solid #ffffff;
		border-left: none;
		position: absolute;
		top: 20px;
		left: -15px;
	}
	> img {
		margin-right: 20px;
	}
}
</style>
