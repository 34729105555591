import Vue from 'vue';
import App from './App.vue';
import "lib-flexible/flexible.js";
import VueRouter from 'vue-router';
import router from './router/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Video from 'video.js';
import 'video.js/dist/video-js.css';
import vueEsign from 'vue-esign';
import BaiduMap from 'vue-baidu-map';
import axios from 'axios'
Vue.config.productionTip = false;
Vue.use(vueEsign);
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(BaiduMap,{
  ak: "rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR"
})
Vue.prototype.$video = Video;
Vue.prototype.$axios = axios 
new Vue({
  render: h => h(App),
  router,
}).$mount('#app');
