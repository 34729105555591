<template>
<div class="my-outbox">
  <div class="my-inbox" ref='box'>
    <div class="my-list" v-for="(item,index) in sendVal" :key='index' ref='list'>
      <span id="node" class="my-uname">{{item.detail}}</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'my-marquee-left',
  props: {
    sendVal: Array
  },
  data () {
    return {
      // 定时器标识
      nowTime: null,
      // 每一个内容的宽度
      disArr: []
    }
  },
  mounted () {
    // var that = this
    var item = this.$refs.list
    console.log('this.$refs.list',this.$refs.list)
    var len = this.sendVal.length
    var arr = []
    let node = document.getElementById('node').getBoundingClientRect().width
    for (var i = 0; i < len; i++) {
      arr.push(node - item[i].clientWidth + 40) // 把宽度和 margin 加起来就是每一个元素需要移动的距离
    }
    this.disArr = arr
    this.moveLeft()
  },
  beforeDestroy () {
    // 页面关闭清除定时器
    clearInterval(this.nowTime)
    // 清除定时器标识
    this.nowTime = null
  },
  methods: {
    // 移动的方法
    moveLeft () {
      var that = this
      var outbox = this.$refs.box
      // 初始位置
      var startDis = 0
      console.log('that.disArr: ', that.disArr)
      this.nowTime = setInterval(function () {
        startDis -= 0.8
        // console.log('初始化移动：', startDis)
        if (Math.abs(startDis) > Math.abs(that.disArr[0])) {
          console.log('come if .....')
          // 每次移动完一个元素的距离，就把这个元素的宽度
          that.disArr.push(that.disArr.shift())
          // 每次移动完一个元素的距离，就把列表数据的第一项放到最后一项
          that.sendVal.push(that.sendVal.shift())
          startDis = 0
          // console.log('移动')
        } else {
          // console.log('不来不来就不来...')
        }
        // 每次都让盒子移动指定的距离，在我自己做的项目中，这种字符串拼接的方法并没有生效
        // outbox.style = 'transform: translateX3d(' + startDis + 'px)' 
        // 后面换了es6的模板字符串就可以了
        outbox.style = `transform: translateX(${startDis}px)`
        // outbox.style = 'transform: translateX(\' + startDis + \' px)'
        // outbox.style.marginLeft = 'startDis'
        // console.log('这里:', startDis)
      }, 1000 / 40)
    }
  }
}
</script>

<style lang="less" scoped>
.my-outbox{
  overflow: hidden;
  .my-inbox{
    white-space: nowrap;
  }
}
</style>
