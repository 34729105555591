import VueRouter from 'vue-router';

import login from '../page/login';
import home from '../page/home';
import test from '../page/test';


export default new VueRouter({
    routes:[               
        {
            path:'/',        
            redirect:'/login'
        },
        {
            path:'/login',
            component: login,
            meta:{
                title:'登录'
            }
        },
        {
            path:'/home',
            component: home,
            meta:{
                title:'嘉兴事故云处'
            }
        },
        {
            path:'/test',
            component: test,
            meta:{
                title:'嘉兴事故云处'
            }
        }
    ]
})