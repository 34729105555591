<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
    @import "./assets/css/reset.css";
    html{
      font-family: PingFangSC-Regular;
      font-size: 32px;
      line-height: 32px;
    }
    .head-title{
      position:fixed;
      z-index: 2;
      top: 0;
      left: 0;
      height: 120px;
      width: 750px;
      font-size: 36px;
      color: #333333;
      letter-spacing: 0;
      text-align: center;
      line-height: 120px;
      font-weight: 600;
      background-color: #FFF;
    }
    .head-title>img{
      position: absolute;
      top: 40px;
      width: 44px;
      height: 44px;
    }
    .head-title-placeholder{
      height: 120px;
    }
    .head-title>img:nth-child(1){ left: 48px; }
    .head-title>img:nth-child(2){ left: 106px; }

    .p30{
      padding: 30px;
    }
    /* 自适应滑动 */
    .div-slide{
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 240px);;
    }
</style>
