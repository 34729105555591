<template>
    <div class="evaStar">
        <ul class="star">
            <li v-for="(itemClass,index) in itemClasses" 
            :key="index" 
            :class="itemClass" 
            class="star-item" 
            @click="stars(index)" 
            track-by="index"></li>
        </ul>
        <div v-if="state">{{state}}</div>
    </div>
</template>


<script>
export default{
    name:'star',
    props:['score'],
    data(){
        return{
            // score: 4
        }
    },
    computed:{ //计算属性
        state(){
            let _score = this.score;
            switch (_score) {
                case 1:_score = '非常差';break;
                case 2:_score = '差';break;
                case 3:_score = '一般';break;
                case 4:_score = '好';break;
                case 5:_score = '非常好';break;
            }
            return _score;

        },
        itemClasses(){
            let result = [];
            let score = Math.floor(this.score * 2 ) / 2;
            let integer = Math.floor(score); 
            for(let i=0;i<integer;i++){ 
                result.push("on"); 
            }
            while(result.length < 5){
                result.push("off");
            }
            return result;
        }
    },
    methods:{
        stars(index){
            this.score = index + 1
        }
    }
}
</script>

 
<style lang="less">
    .evaStar{
        display: flex;
        >div{
            flex: 1;
            font-size: 32px;
            color: #CCCCCC;
            letter-spacing: 0;
            text-align: right;
            height: 50px;
            line-height: 50px;
            font-weight: 400;
        }
    }
    .star{
        font-size: 0;
    }
    .star-item{
        display: inline-block;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        margin-right: 24px;
        background-size: 100%;
    }
    .star-item.on{
        background-image: url(../../assets/img/evaluate_selected.png);
    }
    .star-item.off{
        background-image: url(../../assets/img/evaluate_default.png);
    }
</style>