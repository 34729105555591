<template>
    <div class="viewDetails" v-if="is_viewDetails">
        <div class="viewDetails-content">
            <div class="page-title border-bottom">
                查看详情
                <img @click="switchShow" src="../assets/img/nav_closepage.png" alt="" srcset="">
            </div>
            <div class="div-slide backgroundColor">
                <div class="viewDetails-title">案件详情</div>
                    <div class="Reporteditem">
                        <ul>
                            <li>
                                <div>报案编号:</div>
                                <div>XXXXXXXXXXXXXXX</div>
                                <div>已完成</div>
                            </li>
                            <li>
                                <div>车牌号码:</div>
                                <div>浙Fxxxxxx</div>
                            </li>
                            <li>
                                <div>事故类型:</div>
                                <div>追尾</div>
                            </li>
                            <li>
                                <div>报警时间:</div>
                                <div>2021-05-31 17:23:30</div>
                            </li>
                            <li>
                                <div>事故地点:</div>
                                <div>嘉兴市南湖区中山东路1156号</div>
                            </li>
                        </ul>
                    </div>
                <div class="viewDetails-title">事故认定正文</div>
                <div class="viewDetails-info">
                    <div>2021年05月31号17时23分，张三(驾驶证号：xxxxxxxxxxxxxxxxxx )驾驶车牌号为浙Fxxxxxx小型汽车，在浙江省嘉兴市南湖区中山东路1156号与李四( 驾驶证号： xxxxxxxxxxxxxxxxxx )驾驶车牌号为浙Fxxxxxx小型汽车发生两车损坏的交通事故。</div>
                    <div>当事人张三在同车道行驶中，不按规定与前车保持必要距离的，负全部责任：当事人李四无责任。</div>
                </div>
                <div class="viewDetails-title">保险理赔</div>
                <div class="Reporteditem">
                    <ul>
                        <li>
                            <div>保险公司:</div>
                            <div>XXXXXXXXXXXXXX</div>
                        </li>
                        <li>
                            <div>保险信息:</div>
                            <div></div>
                        </li>
                        <li>
                            <div>理赔状态:</div>
                            <div>办理中</div>
                        </li>
                        <li>
                            <div>理赔金额:</div>
                            <div>XXX元</div>
                        </li>
                        <li>
                            <div>备注:</div>
                            <div>无</div>
                        </li>
                    </ul>
                </div>
                <el-button class="certificate" @click="certificate">查看事故认定书</el-button>
                
            </div>

        </div>
    </div>
</template>
<script>

export default {
    name:'viewDetails',
    data() {
        return {
            is_viewDetails:false, //控制显示
        }
    },
    methods:{
        switchShow(){
            this.is_viewDetails = !this.is_viewDetails;
        }
    }
}
</script>
<style scoped lang="less">
    .viewDetails-info{
        background: #FFFFFF;
        font-size: 32px;
        color: #333333;
        letter-spacing: 0;
        line-height: 40px;
        font-weight: 400;
        margin: 0 35px;
        padding: 40px;
        border-radius: 32px;
        >div{
            text-indent:2em;
        }
    }
    .viewDetails{
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        z-index: 99;
        .viewDetails-content{
            background-color: #FFF;
            border-radius: 32px 32px 0 0;
            width: 750px;
            margin-top: auto;

        }
    }
    .viewDetails-title{
        font-size: 32px;
        color: #333333;
        letter-spacing: 0;
        line-height: 32px;
        font-weight: 600;
        margin: 40px 30px;
    }
    .backgroundColor{
        background-color: #f4f4f4;
    }
    .certificate{
        width: 100%;
        height: 104px;
        font-size: 32px;
        color:  #668BFF;
        letter-spacing: 0;
        font-weight: 600;
        margin-top: 48px;
    }
    .Reporteditem{
        width: 686px;
        background: #FFFFFF;
        margin: 25px auto 0 auto;
        border-radius: 32px;
        ul{
            padding: 40px;
            padding-bottom: 16px;
            font-size: 28px;
            letter-spacing: 0;
            line-height: 28px;
            li{
                display: flex;
                margin-bottom: 24px;
                >div:nth-child(1){
                    width: 140px;
                    font-weight: 600;
                    color: #333333;
                    display: inline-block;
                    text-align: right;
                }
                >div:nth-child(2){
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    margin-left: 10px;
                    -webkit-box-orient: vertical;
                    width: 305px;
                    color: #666666;
                }
                >div:nth-child(3){
                    color: #979BA3;
                    text-align: right;
                    width: 165px;
                    >div{
                        text-align: center;
                        line-height: 56px;
                        margin-left: 40px;
                        width: 136px;
                        height: 56px;
                        border-radius: 8px;
                        color: #668BFF;
                        border: 1px solid #668BFF;
                    }
                }
            }
        }
    }
</style>