<template>
    <div class="login"> 
        <div class="head-title">
            <img src="../assets/img/nav_leftreturn.png" alt="">
            <img src="../assets/img/nav_closepage.png" alt="">
            <div>登录</div>
        </div>
        <div class="head-title-placeholder"></div>
        <div>
            <div class="login-title">登录-嘉兴事故云处</div>
            <div class="login-div">
                <el-form :model="loginForm" :rules="formRules" ref="loginForm" :inline="false">
                    <el-form-item prop="phone">
                        <el-input v-model="loginForm.phone"
                                  type="number" 
                                  maxlength="11"
                                  oninput="if(value.length>11)value=value.slice(0,11)"
                                  placeholder="请输入手机号">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="verifyCode">
                        <el-input class="verigyCode" 
                                  v-model="loginForm.verifyCode" 
                                  maxlength="4"
                                  oninput="if(value.length>11)value=value.slice(0,4)"
                                  placeholder="短信验证码">
                        </el-input>
                        <el-button class="getVerigyCode" 
                                   type="primary" 
                                   size="default" 
                                   :disabled="loginForm.phone.length!=11"
                                   @click="getVerigyCode">
                                   {{verifyCodeHint}}
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox  
                            text-color="#848687" 
                            fill="#668bff"
                            class="agreement" 
                            v-model="loginForm.agreement">
                            我已同意
                            <router-link class="a" to="/">《嘉兴事故云处用户协议》</router-link>
                        </el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="onSubmit" 
                                    type="primary"
                                    :disabled="if_onSubmit"
                                    @click="onSubmit">登陆
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            verifyCodeHint:'获取验证码',
            btnBool:false,    //验证拦截
            CodeTimer:null,   //定时器
            loginForm:{
                phone:'',     //手机号
                verifyCode:'',//验证码
                agreement:false,//协议
            },
            formRules:{
                phone:[
                    {
                        required:true,
                        message:'请输入手机号',
                        trigger:'blur'
                    },
                    {   min: 11, 
                        message: '请输入正确的手机号码', 
                        trigger: 'blur'
                    }
                ],
                verifyCode:[
                    {
                        required:true,
                        message:'请输入验证码',
                        trigger:'blur'
                    }
                ]
            }
        }
    },
    computed:{
        if_onSubmit(){
            return this.loginForm.phone.length!=11 || !this.loginForm.agreement || !this.loginForm.verifyCode
        },
    },
    methods:{
        //获取验证码
        getVerigyCode(){
            if(this.btnBool)return;
            this.btnBool = true;
            let i = 60;
            this.CodeTimer = setInterval(() => {
                this.verifyCodeHint =  i + 's';
                i--;
                if (i < 0) {
                    this.btnBool = false;
                    this.verifyCodeHint = '获取验证码';
                    clearInterval(this.CodeTimer);
                }
            }, 1000);
        },
        //验证进入首页
        onSubmit(){
            this.$router.push({path:'/home'})
        }
    },
    beforeDestroy(){
        //清空定时器
        clearInterval(this.CodeTimer);

    }
}
</script>
<style lang="less">
    html{
        background-color: #FFF;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #848687;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
        border-color: #668BFF;
        background: #668BFF;
        border-radius: 50%;
    }
    .login{
        font-family: PingFangSC-Regular;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0;
        font-weight: 400;
    }
    .login-title{
        font-size: 52px;
        color: #333333;
        letter-spacing: 0;
        line-height: 43px;
        font-weight: 600;
        padding: 60px 48px 0 48px;
    }
    .a{
        font-size: 28px;
        color: #668BFF;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 400;
    }
    .login-div .el-input__inner,.login-div .el-button{
        font-family: PingFangSC-Regular;
        font-size: 32px;
        line-height: 32px;
        // background: #F3F3F3;
        border-radius: 8px;
        // border-color: #F3F3F3;
        height: 88px;
        vertical-align: middle;
    }
    .el-input__inner:focus{
        border-color: #409eff;
    }
    .login-div{
        padding: 80px 48px 56px 48px;
    }
    .verigyCode{
        width: 372px;
        margin-right: 40px;
    }
    .getVerigyCode{
        width: 242px;
        background: #668BFF;
    }
    .agreement{
        font-size: 45px;
        color: #848687;
        letter-spacing: 0;
        line-height: 45px;
        font-weight: 400;
    }
    .login-div .el-checkbox__inner{
        width: 35px;
        height: 35px;
        font-size: 32px;
        line-height: 32px;
        color: #FFFFFF;
        border-radius: 50%;
    }
    .el-checkbox__label{
        font-size: 28px;
        line-height: 28px;
    }
    .onSubmit{
        width: 654px;
        background: #668BFF;
    }
    .el-checkbox__inner::after {
        box-sizing: content-box;
        content: "";
        height: 18px;
        width: 18px;
        border-radius: 14px;
        left: 7px;
        position: absolute;
        top: 6.5px;
        transform: scaleY(0);
        transition: transform .15s ease-in .05s;
        transform-origin: center;
        background-color: #fff;
        border: none;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner::after {
        transform: scaleY(1);
    }
    .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
        color: #FFF;
        background-color: #c2d1ff;
        border-color: #c2d1ff;
    }
    .el-button--primary:focus, .el-button--primary:hover {
        background: #7a9bff;
        border-color: #7a9bff;
        color: #FFF;
    }
</style>